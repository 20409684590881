@import '../../../../scss/theme-bootstrap';

.gnav-section-formatter {
  color: $color--black;
  font-family: $helvetica-font;
  font-size: 18px;
  line-height: 1.2;
  @include breakpoint($bp--largest-up) {
    font-size: 14px;
    background-color: $color--black;
  }
  &__content-wrapper {
    display: flex;
    flex-direction: column;
    height: auto;
    opacity: 1;
    position: relative;
    width: 100%;
    @include breakpoint($bp--largest-up) {
      background-color: $color--white;
      display: block;
      overflow-y: auto;
      padding: 35px $container-pad-landscape 30px;
      position: fixed;
      .social-shoppe-enabled & {
        top: $social-shopping-bar-height + $navbar-height;
        @include breakpoint($bp--largest-up) {
          top: $social-shopping-bar-height-large + $navbar-height;
        }
      }
    }
    // gnav section hidden state
    .gnav-section-formatter[aria-expanded='false'] & {
      flex-direction: column;
      flex-basis: auto;
      height: 0;
      opacity: 0;
      position: absolute;
      display: none;
      @include breakpoint($bp--largest-up) {
        display: block;
      }
    }
  }
  &__gnav-links-wrapper {
    display: flex;
    flex-direction: column;
    height: 0;
    order: 2;
    width: 100%;
    @include breakpoint($bp--largest-up) {
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      max-width: 100%;
      position: relative;
      order: unset;
    }
    .gnav-section-formatter--hide-touts & {
      .gnav-tout {
        display: none;
        @include breakpoint($bp--largest-up) {
          display: inherit;
        }
      }
    }
    .gnav-section-formatter--tout-enabled.gnav-section-formatter--mobile-tout-last & {
      order: 1;
    }
    .gnav-section-formatter[aria-expanded='true'] & {
      height: auto;
      @include breakpoint($bp--largest-up) {
        height: 100%;
      }
    }
    > div {
      padding: 0;
      @include breakpoint($bp--largest-up) {
        flex: 0 calc(25% - 40px); // sustract the gap
        margin-#{$rdirection}: 40px;
      }
      &:last-child {
        @include breakpoint($bp--largest-up) {
          margin-#{$rdirection}: 0;
        }
      }
    }
    > .gnav-links:not(.gnav-links--columns-break) {
      flex: 0 calc(12.5% - 40px); // sustract the gap
    }
    .gnav-links:only-of-type,
    .gnav-links.only-gnav-link {
      .gnav-links__header {
        display: none;
        @include breakpoint($bp--largest-up) {
          display: flex;
        }
      }
      .gnav-links__content {
        @include swap_direction(padding, 0 0 50px);
        display: block;
        background-color: inherit;
        @include breakpoint($bp--largest-up) {
          padding-#{$ldirection}: 0;
        }
      }
      .gnav-links__link {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-family: $ano-black_regular-font;
        height: $gnav-mobile-link-height;
        line-height: $gnav-mobile-link-height;
        margin: 0 $gnav-mobile-hpad--outer;
        border-bottom: none;
        box-shadow: 0 1px 0 0 $color--gray--white;
        text-decoration: none;
        @include breakpoint($bp--largest-up) {
          display: block;
          cursor: auto;
          font-family: $helvetica-font;
          height: auto;
          line-height: 3;
          margin: 0;
          box-shadow: none;
        }
        &:nth-last-child(2) {
          box-shadow: none;
        }
        &--break {
          display: none;
          @include breakpoint($bp--largest-up) {
            display: block;
          }
        }
        &--viewall {
          border-top: 1px solid $color--gray--white;
          @include breakpoint($bp--largest-up) {
            border: none;
          }
        }
        .gnav-link {
          text-transform: uppercase;
          @include breakpoint($bp--largest-up) {
            text-transform: none;
          }
        }
      }
    }
  }
  &__tout {
    @include swap_direction(padding, 20px $gnav-mobile-hpad--outer 0);
    order: 1;
    @include breakpoint($bp--largest-up) {
      display: none;
    }
    .gnav-section-formatter--tout-enabled.gnav-section-formatter--mobile-tout-last & {
      order: 2;
      padding-top: 0;
    }
    &:empty {
      display: none;
    }
  }
}
